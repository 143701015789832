*{
  margin: 0;
  padding: 0;
}

body{
  font-family: Roboto;
  background-color: #ffffff;
  color: #605b55
}

.main{
  display: flex;
  margin: 10px;
}

.card-main{
  width: 900px;
  margin:  5px auto 0px;
  border-radius: 10px;
  overflow: hidden;
  background: white;
  border: solid 1px #ededed;
}

.body-main{
  display: flex;
  height: 580px;
  position: relative;
  background-image: url(./assets/bg.png);
  background-position: right bottom;
  background-size: contain;
  background-repeat: no-repeat;
}


.body-main::before{
  content: '';
  background-color: #7d98595c;
  width: 130px;
  height: 210px;
  position: absolute;
  transform: rotate(65deg);
  top: -60px;
  left: -50px;
}

.header-card{
  width: 100%;
  border-bottom: 10px solid #ededed;
  position: relative;
  display: flex;
  flex-direction: row;    
  justify-content: space-between;
}

.header-card img{
  width: 20%
}

.header-section-button{
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 10px;  
}

.header-section-button button{
  border: none;
  padding: 10px;
  font-weight: 600;
  background-color: #f5f4ef; 
  border-radius: 8px 8px 0px 0px;
  border-left: solid 2px #fff;
}

.header-section-button button.active{
  color: #f5f4ef;
  background-color: #605b55;
}

.section-categories{
  box-shadow: 1px 11px 20px 0px #bbbbbb;
  display: flex;
  flex-direction: column;
}

.category-question{
  width: 200px;
  flex: 1;
  position: relative;
  color: #efefef;
  padding: 10px;
  letter-spacing: -.05em;
  line-height: 1.2em;
  text-shadow: 1px 1px 2px rgb(0 0 0 / 30%);
  font-weight: 700;
  overflow-y: clip;
  background: #d1d1d1;
}

.category-question h2{
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 35px;
  justify-content: center;
}

.category-question::after{
  content: '';
  background-color: #ffffff29;
  width: 120px;
  height: 200px;
  position: absolute;
  transform: rotate(71deg);
  top: -80px;
  left: -80px;
}

.category-question.cat1{
  background-image: url(./assets/bg-c1.png);
  background-size: 150px;
  background-repeat: no-repeat;
  background-position: bottom right;
}
.category-question.cat1.selected{
  background: #8fa769;
  background-image: url(./assets/bg-c1.png);
  background-size: 150px;
  background-repeat: no-repeat;
  background-position: bottom right;
}

.category-question.cat2{
  background-image: url(./assets/bg-c2.png);
  background-size: 150px;
  background-repeat: no-repeat;
  background-position: bottom right;
}
.category-question.cat2.selected{
  background: #a7a52e;
  background-image: url(./assets/bg-c2.png);
  background-size: 150px;
  background-repeat: no-repeat;
  background-position: bottom right;
}

.category-question.cat3{
  background-image: url(./assets/bg-c3.png);
  background-size: 150px;
  background-repeat: no-repeat;
  background-position: bottom right;
}
.category-question.cat3.selected{
  background: #4c9b9f;
  background-image: url(./assets/bg-c3.png);
  background-size: 150px;
  background-repeat: no-repeat;
  background-position: bottom right;
}

.category-question p{
  margin-top: 20px;
  font-size: 15px;
  font-weight: 400;
}

.category-question.selected:before {
  content: "";
  position: absolute;
  top: 0;
  left: 100%;
  width: 0;
  height: 0;
  border-width: 25px;
  border-style: solid;
}
.category-question.cat1.selected:before {
  border-color: transparent transparent transparent #8fa769;
}
.category-question.cat2.selected:before {
  border-color: transparent transparent transparent #a7a52e;
}
.category-question.cat3.selected:before {
  border-color: transparent transparent transparent #4c9b9f;
}

.footer-category{
  position: absolute;
  bottom: 0px;
  display: flex;
  width: 90%;
  padding: 10px 0px;
  justify-content: space-between;
}

.footer-category p{
  font-size: 13px;
  float: left;
  margin: 0;
}

ul.check-questions {
  position: absolute;
  left: 10px;
  bottom: 10px;
  float: left;
  width: auto;
}

ul.check-questions li {
  box-shadow: 1px 1px 3px rgba(0,0,0,.3);;
  -moz-box-shadow: 1px 1px 3px rgba(0,0,0,.3);
  background-color: #fff;
  display: block;
  float: left;
  height: 16px;
  margin-right: 5px;
  padding: 0;
  text-align: center;
  width: 16px;
}

ul.check-questions li span {
  text-decoration: none;
  font-size: 0;
  margin: 0;
  padding: 0;
  border: 0;
  background: url(./assets/check.jpg) scroll 0 0 #fff;
  width: 16px;
  height: 16px;
  display: block;
}

.section-questions{
  padding: 30px 40px;
  width: 100%;
  position: relative;
}

.question h3{
  margin-bottom: 15px;
  letter-spacing: -1px;
}

.question h1, .question p, .question label{
  margin: 20px;
  letter-spacing: -1px;
}

p.errorInput{
  color: #db0000;
  font-size: 15px;
  letter-spacing: 1.1px;
}

.question ul{
  height: 230px;
  display: flex;
  flex-direction: column;
  list-style: none;
  flex-wrap: wrap;
}

.question ul li{
  margin: 5px;
  position: relative;
  display: flex;
  align-items: center;
}

.question ul li.InputList label{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px !important;
  justify-content: flex-start;
}

.question ul li.InputList label strong{
  width: 100px;
}

li.InputList button.input-delete{
  position: absolute;
  border: none;
  background: #ff9595;
  color: white;
  border-radius: 50px;
  padding: 3px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 265px;
  top: -5px;
}


.question h1{
  font-size: 25px;
  letter-spacing: -1px;
}

:focus {
  outline: 0;
}

.question input[type='number']{
  margin: 0 10px;
  border: 1px solid #999;
  padding: 1px;
  font-size: 15px;
  width: 150px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  padding: 8px;
  color: #605b55;
  font-weight: 600;
}

.footer-terminate{
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 30px;
  width: 93%;
  display: flex;
  justify-content: space-between;
}

.footer-questions{
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 30px;
  width: 67%;
  display: flex;
  justify-content: space-between;
}

.footer-questions.next{
  justify-content: flex-end !important;
}

.footer-questions button, .footer-terminate button{
  border: none;
  padding: 10px;
  border-radius: 6px;
  font-size: 1em;
  letter-spacing: 1px;
  display: flex;
  align-content: center;
  cursor: pointer;
}

.footer-button--next, .footer-terminate--next{
  color: white;
  background-color: #43614f ;
}

.footer-button--prev, .footer-terminate--prev{
  color: white;
  background-color: #999 ;
}

.content-select{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 8px 0px;
}

.select{
  flex: 0.95;
}

.button-general{
  border: none;
  padding: 10px;
  border-radius: 6px;
  font-size: 15px;
  letter-spacing: 0px;
  display: flex;
  align-items: center;
  background: #43614f;
  color: white;
}

.CardWelcome{
  display: flex;
  text-align: center;
  margin: auto;
  flex-direction: column;
}

.text-wellcome{
  flex: 1.25;
  margin-left: 45px;
  text-align: left;
}

.text-wellcome h2{
  font-size: 55px;
  font-weight: 900;
  line-height: 50px;
  letter-spacing: -3px;
  margin-bottom: 65px;
}

.text-wellcome h3{
  font-size: 25px;
  font-weight: 400;
  letter-spacing: -2px;
}

.CarWelcome-buttons{
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
}

.CarWelcome-buttons p{
  margin-bottom: 10px;
  font-weight: 400;
  letter-spacing: -1px;
}

.CarWelcome-buttons button{
  border: none;    
  font-size: 1.2em;
  letter-spacing: 1px;
  padding: 20px;
  margin: 5px;
  width: 260px;
  border-radius: 15px;
  background: #43614f;
  color: white;
  cursor: pointer;
}

.CarWelcome-buttons button:hover, .CarWelcome-buttons button:focus{
  background: #517660;
}

.emissions-total{
  font-size: 25px;
  background: linear-gradient(#7d9859 10%, #6a814a );
  width: 500px;
  margin: 40px auto 10px;
  padding: 25px 15px;
  color: #fff;
  letter-spacing: -.05em;
  text-shadow: 1px 1px 2px rgb(0 0 0 / 30%);
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  background-repeat: no-repeat;
  text-align: center;
}

.cardTerminate{
  width: 100%;
}

.cardTerminate h1{
  font-size: 35px;
  font-weight: 900;
  line-height: 40px;
  letter-spacing: -1px;
  text-align: center;
  width: 60%;
  margin: 15px auto;
}

.cardTerminate h3 span{
  font-weight: 400;
  font-size: 25px;
}

.message{
  margin: 25px 8%;
  text-align: justify;
}

.message a{
  color: #6b834b;
}

.message h2 {
  margin: 0 0 15px;
  font-size: 30px;
}
.message p {
  margin: 0 0 10px;    
  font-size: 18px;
  letter-spacing: -1px;
}

.logo-terminate{
  width: 160px;
  position: absolute;
  top: 5px;
  right: 5px;
}


@media (max-width: 320px){

  .header-card{
    flex-direction: row;
    justify-content: center;
  }

  .header-card img {
    width: 60%;
  }

  .category-question{
    height: 80px;
  }

  .category-question h2{
    font-size: 15px;
  }

  .category-question p:not(ul.check-questions p){
    display: none;
  }

  .body-main{
    flex-direction: column;
    height: 630px;
  }

  .section-categories{
    flex-direction: row;
    box-shadow: 0px 1px 20px 0px #bbbbbb;
  }

  .section-questions{
    padding: 35px 10px;
    width: auto;
  }
  .category-question::after{
    width: 140px;
    height: 100px;
    top: -80px;
    left: -60px;
  }

  .category-question.cat1{
    background-size: 70px;
  }
  .category-question.cat2{
    background-size: 70px;
  }
  .category-question.cat3{
    background-size: 70px;
  }

  .category-question.selected:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 30%;
    width: 0;
    height: 0;
    border-width: 25px;
    border-style: solid;
  }
  .category-question.cat1.selected:before {
    border-color: #8fa769 transparent transparent transparent;
  }
  .category-question.cat2.selected:before {
    border-color: #a7a52e transparent transparent transparent;
  }
  .category-question.cat3.selected:before {
    border-color: #4c9b9f transparent transparent transparent;
  }


  .question h1, .question p, .question label{
    margin: 10px;
  }

  .question h1{
    font-size: 20px;
    text-align: justify;
  }

  .question p, .question label{
    font-size: 15px;  
    text-align: justify;
  }

  p.errorInput{
    font-size: 13px;
  }

  .question ul{
    height: 180px;
    overflow: scroll;
    flex-wrap: nowrap;
  }

  .question ul li {
    display: flex;
    flex-direction: column;
  }

  .css-b62m3t-container{
    width: 100%;
  }

  li.InputList button.input-delete{
    left: 91%;
  }

  .footer-questions{
    margin: 8px 24px;
    width: 84%;
  }

  .emissions-total{
    margin: 10px 20px;
    width: auto;
    font-size: initial;
  }
  
  .emissions-total h3{
    font-size: 20px;
  }
  
  .emissions-total p{
    font-size: 14px;
  }

  .text-wellcome h2 {
    font-size: 40px;
    text-align: center;
  }

  .CarWelcome-buttons {
    margin-top: 15px;
  }

  .CardWelcome{
    flex-direction: column;
  }

  .text-wellcome {
    margin-left: 15px;
  }
  
  .text-wellcome h3 {
    font-size: 23px;
  }

  .logo-terminate{
    width: 100px;
    top: 15px;
  }

  .body-main::before{
    top: -80px;
    left: -130px;
  }

  .cardTerminate h1{
    margin-top: 65px;
    font-size: 25px;
    line-height: 27px;
    width: 100%;
  }

  .message{
    margin: 10px;
  }

  .message p{
    font-size: 15px;
  }

  .footer-terminate{
    margin: 8px;
  }

  .footer-terminate button{
    padding: 7px;
    font-size: 0.8em;
  }

  .CarWelcome-buttons button{
    width: 200px;
  }
}

@media (min-width: 321px ) and (max-width: 600px) {

  .header-card{
    flex-direction: row;
    justify-content: center;
  }

  .header-card img {
    width: 40%;
  }

  .category-question{
    height: 80px;
  }

  .category-question h2{
    font-size: 15px;
  }

  .category-question p:not(ul.check-questions p){
    display: none;
  }

  .body-main{
    flex-direction: column;
    height: 630px;
  }

  .section-categories{
    flex-direction: row;
    box-shadow: 0px 1px 20px 0px #bbbbbb;
  }

  .section-questions{
    padding: 35px 10px;
    width: auto;
  }

  .category-question::after{
    width: 140px;
    height: 100px;
    top: -80px;
    left: -60px;
  }

  .category-question.cat1{
    background-size: 70px;
  }
  .category-question.cat2{
    background-size: 70px;
  }
  .category-question.cat3{
    background-size: 70px;
  }

  .category-question.selected:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 30%;
    width: 0;
    height: 0;
    border-width: 25px;
    border-style: solid;
  }
  .category-question.cat1.selected:before {
    border-color: #8fa769 transparent transparent transparent;
  }
  .category-question.cat2.selected:before {
    border-color: #a7a52e transparent transparent transparent;
  }
  .category-question.cat3.selected:before {
    border-color: #4c9b9f transparent transparent transparent;
  }

  .question h1, .question p, .question label{
    margin: 10px;
  }

  .question h1{
    font-size: 20px;
    text-align: justify;
  }

  .question p, .question label{
    font-size: 15px;  
    text-align: justify;
  }

  p.errorInput{
    font-size: 13px;
  }

  .question ul{
    height: 180px;
    overflow: scroll;
    flex-wrap: nowrap;
  }

  .question ul li {
    display: flex;
    flex-direction: column;
  }

  .css-b62m3t-container{
    width: 100%;
  }


  li.InputList button.input-delete{
    left: 265px;
  }

  .footer-questions{
    margin: 8px 24px;
    width: 84%;
  }

  .emissions-total{
    margin: 10px 20px;
    width: auto;
    font-size: initial;
  }
  
  .emissions-total h3{
    font-size: 25px;
  }
  
  .emissions-total p{
    font-size: 20px;
  }

  .text-wellcome h2 {
    font-size: 50px;
  }

  .CarWelcome-buttons {
    margin-top: 15px;
  }

  .CardWelcome{
    flex-direction: column;
  }

  .text-wellcome {
    margin-left: 15px;
  }

  .text-wellcome h3 {
    font-size: 23px;
  }

  .body-main::before{
    top: -80px;
    left: -130px;
  }

  .cardTerminate h1{
    margin-top: 65px;
    font-size: 30px;
    line-height: 35px;
    width: 90%;
  }

  .message{
    margin: 10px;
  }

  .message p{
    font-size: 18px;
  }

  .footer-terminate{
    margin: 8px;
  }

  .footer-terminate button{
    padding: 7px;
    font-size: 0.9em;
  }

  .CarWelcome-buttons button{
    width: 200px;
  }
}

@media (min-width: 601px ) and (max-width: 767px) {

  .header-card{
    flex-direction: row;
    justify-content: center;
  }

  .header-card img {
    width: 30%;
  }

  .category-question{
    height: 100px;
  }

  .category-question h2{
    font-size: 15px;
  }

  .category-question p:not(ul.check-questions p){
    display: none;
  }

  .body-main{
    flex-direction: column;
    height: 630px;
  }

  .section-categories{
    flex-direction: row;
    box-shadow: 0px 1px 20px 0px #bbbbbb;
  }

  .section-questions{
    padding: 35px 10px;
    width: auto;
  }

  .category-question::after{
    width: 140px;
    height: 100px;
    top: -80px;
    left: -60px;
  }

  .category-question.cat1{
    background-size: 80px;
  }
  .category-question.cat2{
    background-size: 80px;
  }
  .category-question.cat3{
    background-size: 80px;
  }

  .category-question.selected:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 30%;
    width: 0;
    height: 0;
    border-width: 25px;
    border-style: solid;
  }
  .category-question.cat1.selected:before {
    border-color: #8fa769 transparent transparent transparent;
  }
  .category-question.cat2.selected:before {
    border-color: #a7a52e transparent transparent transparent;
  }
  .category-question.cat3.selected:before {
    border-color: #4c9b9f transparent transparent transparent;
  }

  .question h1, .question p, .question label{
    margin: 10px;
  }

  .question h1{
    font-size: 20px;
    text-align: justify;
  }

  .question p, .question label{
    font-size: 15px;  
    text-align: justify;
  }

  p.errorInput{
    font-size: 13px;
  }

  .question ul{
    height: 190px;
  }

  li.InputList button.input-delete{
    left: 250px;
  }

  .question ul li.InputList label strong {
    width: 85px;
  }

  .footer-questions{
    margin: 8px 24px;
    width: 84%;
  }

  .emissions-total{
    margin: 10px 20px;
    width: auto;
    font-size: initial;
  }
  
  .emissions-total h3{
    font-size: 25px;
  }
  
  .emissions-total p{
    font-size: 20px;
  }

  .text-wellcome h2 {
    font-size: 50px;
  }

  .CarWelcome-buttons {
    margin-top: 20px;
  }

  .CardWelcome{
    flex-direction: column;
  }

  .text-wellcome {
    margin-left: 25px;
  }

  .text-wellcome h3 {
    font-size: 25px;
  }

  .cardTerminate h1{
    margin-top: 65px;
    font-size: 35px;
    line-height: 40px;
    width: 90%;
  }
}

@media (min-width: 768px ) and (max-width: 860px) {
  .category-question {
    width: 160px;
  }

  .question ul li.InputList label strong{
    width: 90px;
    font-size: 15px;
  }

  .question input[type='number']{
    width: 120px;
  }

  li.InputList button.input-delete{
    left: 225px;
  }
}